import React, { useEffect, useRef, forwardRef, useState, useCallback } from 'react';
import cn from 'classnames';
import { useInView } from 'utils/useInView';
import css from './LazyVideo.module.scss';

interface LazyVideoProps {
  src: string;
  autoplay: boolean;
  poster?: string;
  // Set 'true' to load the video only once, set 'false' to load/unload each time it enters/leaves the viewport
  once?: boolean;
  className?: string;
  title?: string;
}

export const LazyVideo = forwardRef<HTMLVideoElement, LazyVideoProps>(
  ({ src, autoplay, poster, once, className, title }, ref) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const inView = useInView({ ref: videoRef });
    const [showPlayButton, setShowPlayButton] = useState(false);

    const playVideo = useCallback((video: HTMLVideoElement, retry = true) => {
      if (!video) return;
      video
        .play()
        .then(() => setShowPlayButton(false))
        .catch((e) => {
          console.error('Play error:', e);
          setShowPlayButton(true);
          if (retry) setTimeout(() => playVideo(video, false), 1000);
        });
    }, []);

    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      if (inView) {
        if (!video.src) {
          video.src = src;
          video.preload = 'metadata';
        }
        if (autoplay) {
          playVideo(video);
        }
      } else {
        video.pause();
        video.currentTime = 0;
        if (!once) video.removeAttribute('src');
      }
    }, [inView, src, autoplay, once, playVideo]);

    return (
      <div className={cn('relative', 'overflow-hidden', className, css.video)}>
        <video
          className="w-full block"
          ref={(_ref) => {
            videoRef.current = _ref;
            if (ref) {
              if (typeof ref === 'function') ref(_ref);
              else ref.current = _ref;
            }
          }}
          title={title}
          muted
          playsInline
          loop
          preload="none"
          poster={poster}
          autoPlay={autoplay}
          onPlay={() => setShowPlayButton(false)}
          controls={false}
        />

        {showPlayButton && (
          <button
            type="button"
            className={cn(css.playBtn, 'absolute', 'cursor-pointer', 'place-self-center')}
            onClick={() => videoRef.current && playVideo(videoRef.current, true)}
          />
        )}
      </div>
    );
  },
);
