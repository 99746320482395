import React from 'react';
import cn from 'classnames';
import InfoItem from './InfoItem';
import css from './InfoSection.module.scss';

interface Props {
  items: Array<{ title: string; text: string }>;
  isWhiteText?: boolean;
}

export default function InfoSection({ items, isWhiteText = false }: Props) {
  return (
    <section className={css.sectionWrapper}>
      <div className={cn(css.sectionContent, isWhiteText && css.whiteText)}>
        {items.map((item, index) => {
          return <InfoItem info={item} isWhiteText={isWhiteText} key={index} />;
        })}
      </div>
    </section>
  );
}
