import React from 'react';
import cn from 'classnames';
import PageLayout from 'components/common/PageLayout';

import tr from 'utils/translator';
import InfoBox from 'components/common/InfoBox';
import images from 'assets/images';
import TopSection from 'components/common/TopSection';
import TopImage from 'components/common/TopImage';
import InfoSection from 'components/common/InfoSection';
import TextLeftRight from 'components/common/TextLeftRight';
import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';

import css from './DataAndPrivacy.module.scss';

interface Props {}

const DataAndPrivacy: React.FC<Props> = (props: Props) => {
  const infoTexts = tr.text('dataAndPrivacy.infoSection');
  return (
    <PageLayout isFullWidth>
      <div className={cn(css.dataAndPrivacy, 'flex flex-col items-center')}>
        <TopSection
          title={tr.text('dataAndPrivacy.topSection.title')}
          subtitle={tr.text('dataAndPrivacy.topSection.subTitle')}
          ctaLabel="Get started"
          ctaUrl={SUBSCRIPTION_PLANS_PAGE}
          isBlackTexts
        />
        <TopImage src={images.dataImage} />
        <TextLeftRight
          title={tr.text('dataAndPrivacy.subTitle')}
          text={tr.text('dataAndPrivacy.subTitleDescription')}
        />
        <InfoSection items={infoTexts} />
      </div>
    </PageLayout>
  );
};

export default DataAndPrivacy;
