import React from 'react';
import cn from 'classnames';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import translator from 'utils/translator';

import PageLayout from 'components/common/PageLayout';
import ScrollSection from 'components/common/ScrollSection';

import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';

import css from './AI.module.scss';
import TopSection from 'components/common/TopSection';

type Props = {};

const _t = (path: string) => translator.text(`ai.${path}`);

const AI: React.FC<Props> = () => {
  const isMobile = useResponsiveQuery();
  const CTALabel = 'Start Creating';

  return (
    <PageLayout className={css.ai} isFullWidth isWhiteHeader={false}>
      <TopSection
        title={_t('topSection.title')}
        subtitle={isMobile ? _t('topSection.subTitleMob') : _t('topSection.subTitle')}
        ctaLabel={CTALabel}
        ctaUrl={SUBSCRIPTION_PLANS_PAGE}
      />
      <div className={css.commonInfo} id="#common">
        <div className={css.firstSection}>
          <div className={css.videoWrapper}>
            <div className={css.videoInner}>
              <iframe
                src="https://player.vimeo.com/video/1043051852?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                title="Storycards AI"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <div
            className={cn(css.text, 'text-2')}
            dangerouslySetInnerHTML={{ __html: _t('sections.sectionTop.title') }}
          ></div>
        </div>
        <div className={css.scrollSectionWrapper}>
          <ScrollSection sectionData={_t('scrollSection')} />
        </div>
      </div>
    </PageLayout>
  );
};

export default AI;
