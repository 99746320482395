import images from 'assets/images';
import React from 'react';
import styles from './styles.module.scss';
import translator from 'utils/translator';
import PageLayout from 'components/common/PageLayout';
import ScrollSection from 'components/common/ScrollSection';
import TopSection from 'components/common/TopSection';
import TopImage from 'components/common/TopImage';
import TextLeftRight from 'components/common/TextLeftRight';
import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';
import InfoSection from 'components/common/InfoSection';

function Flow() {
  const _t = (path: string) => translator.text(`flow.${path}`);
  return (
    <PageLayout isFullWidth>
      <div className={`${styles.flowPage} flex flex-col items-center `}>
        <TopSection
          title={_t('topSection.title')}
          subtitle={_t('topSection.subTitle')}
          ctaLabel="Get started"
          ctaUrl={SUBSCRIPTION_PLANS_PAGE}
          isBlackTexts
        />
        <TopImage src={images.flowImage} />
        <TextLeftRight title={_t('subtitle')} text={_t('description')} className={styles.textLeftRight} />
        <div className={`${styles.scrollSectionContent} flex flex-col items-center`}>
          <h2>{_t('scrollSectionTitle')}</h2>
          <div className={styles.subtitle}>{_t('scrollSectionSubTitle')}</div>

          <ScrollSection sectionData={_t('scrollSection')} />
        </div>
        <InfoSection items={_t('infoSection')} />
      </div>
    </PageLayout>
  );
}

export default Flow;
