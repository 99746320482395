import React, { useEffect, useRef, useState } from 'react';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import Header from 'components/common/Header';
import HeadlineSection from './sections/HeadlineSection';
import Clients from './sections/Clients';
import Solutions from './sections/Solutions';
import Portfolio from './sections/Portfolio';
import Footer from 'components/common/Footer';
import AISection from './sections/AISection';
import Create from './sections/Create';
import DesignContent from './sections/DesignContent';
import Personalize from './sections/Personalize';
import NoCodeSolutions from './sections/NoCodeSolutions';
import IntegrationTools from './sections/IntegrationTools';

function Homepage() {
  const isMobile = useResponsiveQuery();
  const headlineSectionRef = useRef<HTMLElement>(null);
  const scrollingSectionRef = useRef<HTMLElement>(null);
  const [invertNavbarColors, setInvertNavbarColors] = useState(false);

  const onScroll = () => {
    if (headlineSectionRef.current && scrollingSectionRef.current) {
      const headlineSectionHeight = headlineSectionRef.current.clientHeight;
      const scrollingSectionHeight = scrollingSectionRef.current.clientHeight;
      if (window.scrollY > headlineSectionHeight) {
        setInvertNavbarColors(true);
        if (window.scrollY > scrollingSectionHeight + headlineSectionHeight) {
          setInvertNavbarColors(false);
        }
      } else {
        setInvertNavbarColors(false);
      }
    }
  };

  const onBeforeUnload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('onbeforeunload', onBeforeUnload);
    return () => {
      onBeforeUnload();
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('onbeforeunload', onBeforeUnload);
    };
  }, []);

  return (
    <>
      <Header isTransparentHeader invertColors={invertNavbarColors || isMobile} isWhite={false} />
      <HeadlineSection innerRef={headlineSectionRef} />
      <Clients />
      <Create />
      <DesignContent />
      <Personalize />
      <AISection />
      <Portfolio />
      <Solutions />
      <NoCodeSolutions />
      <IntegrationTools />
      <Footer />
    </>
  );
}

export default Homepage;
