import React from 'react';

import PageLayout from 'components/common/PageLayout';
import images from 'assets/images';
// import useResponsiveQuery from "utils/useResponsiveQuery";
import translator from 'utils/translator';
import InfoBox from 'components/common/InfoBox';
import TextLeftRight from 'components/common/TextLeftRight';

import css from './Collaboration.module.scss';
import InfoSection from 'components/common/InfoSection';

type Props = {};

const _t = (path: string) => translator.text(`collaboration.${path}`);

const Collaboration: React.FC<Props> = () => {
  // const videoURL = {
  //   mobile: `https://player.vimeo.com/external/569348352.sd.mp4?s=62a32c78736b3d2b82af62cf8b6923bc37402ebf&profile_id=165`,
  //   desktop: `https://player.vimeo.com/external/569348352.hd.mp4?s=b70f85bc177af12b8438ea10c8ec10c56f064d6f&profile_id=169`,
  // };
  // const isMobile = useResponsiveQuery();

  return (
    <PageLayout className={css.collaboration} isFullWidth>
      <h1 className={css.pageTitle}>{_t('title')}</h1>

      <div className={css.imageWrap}>
        <img src={images.collaborationImage} alt="Storycards collaboration" />
      </div>
      <TextLeftRight title={_t('pageInfo.title')} text={_t('pageInfo.text')} />
      <InfoSection items={_t('infoSection')} />
    </PageLayout>
  );
};

export default Collaboration;
