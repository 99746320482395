import React from 'react';

import translator from 'utils/translator';

import ScrollSection from '../ScrollSection';
import styles from './style.module.scss';

const _t = (path: string) => translator.text(`homepage.${path}`);

function Personalize() {
  return (
    <div className={`${styles.personalize} flex flex-col w-full items-center`}>
      <div className={`${styles.content} flex flex-col items-center`}>
        <h2>{_t('personalize.title')}</h2>
        <div className={styles.subtitle}>{_t('personalize.subtitle')}</div>

        <ScrollSection sectionId="personalize" reverse />
      </div>
    </div>
  );
}

export default Personalize;
