import React from 'react';
import cn from 'classnames';
import css from './TopSection.module.scss';

interface Props {
  title: string;
  subtitle: string;
  ctaUrl: string;
  ctaLabel: string;
  isBlackTexts?: boolean;
}

export default function TopSection({ title, subtitle, ctaUrl, ctaLabel, isBlackTexts = false }: Props) {
  return (
    <section className={cn(css.topSection, isBlackTexts && css.blackContent)}>
      <div className={css.info}>
        <h1>{title}</h1>
        <div className={css.subtitleWrapper}>
          <p className={css.subtitle}>{subtitle}</p>
          <a href={ctaUrl} className={css.CTAButton}>
            {ctaLabel}
          </a>
        </div>
      </div>
    </section>
  );
}
