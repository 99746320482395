import React from 'react';
import cn from 'classnames';
import css from './InfoItem.module.scss';

interface Props {
  info: {
    title: string;
    text: string;
  };
  isWhiteText?: boolean;
}
export default function InfoItem({ info, isWhiteText = false }: Props) {
  return (
    <div className={cn(css.infoItem, isWhiteText && css.whiteText)}>
      <h3 className={cn('font-bold', 'text-left')}>{info.title}</h3>
      <p className="whitespace-pre-wrap">{info.text}</p>
    </div>
  );
}
