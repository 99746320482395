import React from 'react';
import translator from 'utils/translator';

import styles from './styles.module.scss';
import ScrollSection from '../ScrollSection';

const _t = (path: string) => translator.text(`homepage.${path}`);

function AISection() {
  return (
    <section className={`${styles.infoSection} flex flex-col w-full items-center`}>
      <div className={styles.content}>
        <h2 className={`${styles.title} font-semibold`}>{_t('ai.title')}</h2>
        <p className={`${styles.subtitle} `}>{_t('ai.subtitle')}</p>

        <ScrollSection sectionId="ai" />
      </div>
    </section>
  );
}

export default AISection;
