import React, { useRef, useState } from 'react';
import cn from 'classnames';
import PageLayout from 'components/common/PageLayout';
import TopSection from 'components/common/TopSection';
import InfoBoxesList, { InfoBoxType } from 'components/common/InfoBoxesList';
import translator from 'utils/translator';
import css from './EditorPage.module.scss';
import images from 'assets/images';
import ScrollSection from 'components/common/ScrollSection';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';
import InfoSection from 'components/common/InfoSection';

type Props = {};

const _t = (path: string) => translator.text(`editorPage.${path}`);

const infoItems: Array<Array<InfoBoxType>> = [
  [
    {
      title: _t('infoItems.designItTitle'),
      text: _t('infoItems.designItText'),
      icon: 'pensil',
    },
    {
      title: _t('infoItems.notMoreTitle'),
      text: _t('infoItems.notMoreText'),
      icon: 'star',
    },
  ],
  [
    {
      title: _t('infoItems.designResponsiveTitle'),
      text: _t('infoItems.designResponsiveText'),
      icon: 'responsive',
    },
    {
      title: _t('infoItems.fontsTitle'),
      text: _t('infoItems.fontsText'),
      icon: 'font',
    },
  ],
  [
    {
      title: _t('infoItems.contentTitle'),
      text: _t('infoItems.contentText'),
      icon: 'plus',
    },
    {
      title: _t('infoItems.platformTitle'),
      text: _t('infoItems.platformText'),
      icon: 'mobile',
    },
  ],
  [
    {
      title: _t('infoItems.createReusableTitle'),
      text: _t('infoItems.createReusableText'),
      icon: 'symbol',
    },
    {
      title: _t('infoItems.gridTitle'),
      text: _t('infoItems.gridText'),
      icon: 'grid',
    },
  ],
  [
    {
      title: _t('infoItems.globalStickyTitle'),
      text: _t('infoItems.globalStickyText'),
      icon: 'layers',
    },
    {
      title: _t('infoItems.animationTitle'),
      text: _t('infoItems.animationText'),
      icon: 'animation',
    },
  ],
  [
    {
      title: _t('infoItems.shareTitle'),
      text: _t('infoItems.shareText'),
      icon: 'share',
    },
    {
      title: _t('infoItems.seoTitle'),
      text: _t('infoItems.seoText'),
      icon: 'search',
    },
  ],
  [
    {
      title: _t('infoItems.userTitle'),
      text: _t('infoItems.userText'),
      icon: 'user',
    },
    {
      title: _t('infoItems.ownCodeTitle'),
      text: _t('infoItems.ownCodeText'),
      icon: 'embed',
    },
  ],
  [
    {
      title: _t('infoItems.marketingTitle'),
      text: _t('infoItems.marketingText'),
      icon: '',
    },
    {
      title: _t('infoItems.revisionTitle'),
      text: _t('infoItems.revisionText'),
      icon: '',
    },
  ],
];

const EditorPage: React.FC<Props> = () => {
  const videoRef = useRef<HTMLVideoElement>(document.createElement('video'));
  const [mute, setMute] = useState(true);
  const isMobile = useResponsiveQuery();
  const CTALabel = 'Start Creating';
  const videoSrc = 'https://assts.stories.sc/website/desktop/design.mp4';
  const videoSrcMob = 'https://assts.stories.sc/website/mobile/design.mp4';
  const videoPoster = 'https://assts.stories.sc/website/poster/design.jpg';
  const infoTexts = _t('infoSection');

  return (
    <PageLayout className={css.editorPage} isFullWidth isWhiteHeader={false}>
      <div className={`flex flex-col items-center `}>
        <TopSection
          title={_t('topSection.title')}
          subtitle={_t('topSection.subtitle')}
          ctaLabel={CTALabel}
          ctaUrl={SUBSCRIPTION_PLANS_PAGE}
        />
        <section className={css.videoSection}>
          <div className={`${css.videoWrap} cursor-pointer relative`}>
            <video
              playsInline
              autoPlay
              ref={videoRef}
              loop
              muted={mute}
              disableRemotePlayback
              src={isMobile ? videoSrcMob : videoSrc}
              onClick={() => setMute(!mute)}
              poster={videoPoster}
            />
            <div
              className={cn(css.text, 'text-2')}
              dangerouslySetInnerHTML={{ __html: _t('videoSectionUnderText') }}
            ></div>
          </div>
        </section>
        <div className={`${css.scrollSectionContent} flex flex-col items-center`}>
          <ScrollSection sectionData={_t('scrollSection')} />
        </div>
        <InfoSection items={infoTexts} isWhiteText />
      </div>
    </PageLayout>
  );
};

export default EditorPage;
