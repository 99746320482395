import React from 'react';
import cn from 'classnames';
import css from './IconsBlock.module.scss';

interface Props {
  title: string;
  items: { icon: string; title: string; text: string }[];
  className?: string;
}
export default function IconsBlock({ title, items, className = '' }: Props) {
  return (
    <div className="flex flex-col items-center">
      <div className={css.iconsBlock}>
        <h3 className="font-bold">{title}</h3>
        <div className={cn(css.info, 'flex')}>
          {items.map(({ icon, title, text }, index) => {
            return (
              <div className={cn(css.item, 'flex flex-col')} key={index}>
                <img src={icon} alt="" />
                <h4 className="font-bold">{title}</h4>
                <p>{text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
