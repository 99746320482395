import React from 'react';
import cx from 'classnames';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import css from './PageLayout.module.scss';

interface Props {
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  isTransparentHeader?: boolean;
  isFullVideo?: boolean;
  isFullWidth?: boolean;
  isWhiteHeader?: boolean;
}

const PageLayout: React.FC<Props> = ({
  className = '',
  contentClassName = '',
  children = null,
  isTransparentHeader = false,
  isFullVideo = false,
  isFullWidth = false,
  isWhiteHeader = true,
}: React.ComponentProps<typeof PageLayout>) => {
  return (
    <div className={cx(css.pageLayout, className)}>
      <Header isTransparentHeader={isTransparentHeader} isWhite={isWhiteHeader} />
      <div
        className={cx(
          css.contentWrap,
          isFullVideo ? css.fullVideo : '',
          contentClassName,
          isFullWidth ? css.fullWidth : '',
        )}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default PageLayout;
