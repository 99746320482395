import React from 'react';

import translator from 'utils/translator';

import styles from './styles.module.scss';

const _t = (path: string) => translator.text(`homepage.${path}`);

function NoCodeSolutions() {
  return (
    <div className={`${styles.noCodeSolutions} flex flex-col w-full items-center`}>
      <div className={styles.content}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: _t('noCodeSolutions.title') }}></h2>
        <div className={styles.items}>
          {(_t('noCodeSolutions.items') as Array<{ title: string; text: string }>).map((item, index) => {
            return (
              <div className={styles.item} key={index}>
                <h4 className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default NoCodeSolutions;
