import images from 'assets/images';
import React from 'react';

import translator from 'utils/translator';
import PageLayout from 'components/common/PageLayout';
import TopSection from 'components/common/TopSection';
import TopImage from 'components/common/TopImage';
import TextLeftRight from 'components/common/TextLeftRight';
import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';
import InfoSection from 'components/common/InfoSection';

import styles from './Hosting.module.scss';

function Hosting() {
  const _t = (path: string) => translator.text(`hosting.${path}`);
  return (
    <PageLayout isFullWidth isWhiteHeader={false} className={styles.hostingPageWrapper}>
      <div className={`${styles.hostingPage} flex flex-col items-center `}>
        <TopSection
          title={_t('topSection.title')}
          subtitle={_t('topSection.subTitle')}
          ctaLabel="Get started"
          ctaUrl={SUBSCRIPTION_PLANS_PAGE}
        />
        <TopImage src={images.hostingHero} className={styles.topImage} />
        <TextLeftRight title={_t('subtitle')} text={_t('description')} isWhite />
        <InfoSection items={_t('infoSection')} isWhiteText />
      </div>
    </PageLayout>
  );
}

export default Hosting;
