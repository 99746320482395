import cn from 'classnames';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import tr from 'utils/translator';
import PageLayout from 'components/common/PageLayout';
import imageDesktop from 'assets/images/segments/segments-image.jpeg';
import imageMobile from 'assets/images/segments/segments-image_mobile.png';
import TopSection from 'components/common/TopSection';
import TopImage from 'components/common/TopImage';
import InfoSection from 'components/common/InfoSection';
import TextLeftRight from 'components/common/TextLeftRight';
import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';
import styles from './segments.module.scss';

function Analytics() {
  const isMobile = useResponsiveQuery();
  const image = isMobile ? imageMobile : imageDesktop;
  const infoTexts = tr.text('segments.infoSection');

  return (
    <PageLayout isFullWidth>
      <div className={cn(styles.segmentsContent, 'flex flex-col items-center')}>
        <TopSection
          title={tr.text('segments.topSection.title')}
          subtitle={tr.text('segments.topSection.subTitle')}
          ctaLabel="Get started"
          ctaUrl={SUBSCRIPTION_PLANS_PAGE}
          isBlackTexts
        />
        <TopImage src={image} className={styles.topImage} />
        <TextLeftRight title={tr.text('segments.subTitle')} text={tr.text('segments.subTitleDescription')} />
        <InfoSection items={infoTexts} />
      </div>
    </PageLayout>
  );
}

export default Analytics;
