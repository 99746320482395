import React from 'react';
import cn from 'classnames';
import styles from './TextLeftRight.module.scss';

interface Props {
  title: string;
  text: string;
  className?: string;
  isWhite?: boolean;
}

export default function TextLeftRight({ title, text, className, isWhite = false }: Props) {
  return (
    <div className={cn(styles.content, `flex flex-col lg:flex-row`, className, isWhite && styles.whiteContent)}>
      <div className={`flex-1`}>
        <h2 className={`${styles.subTitle} flex-1 font-semibold text-center lg:text-left whitespace-wrap`}>{title}</h2>
      </div>
      <div className={`flex-1`}>
        <p className={`${styles.description} flex-1 whitespace-pre-wrap `}>{text}</p>
      </div>
    </div>
  );
}
