import React from 'react';
import cn from 'classnames';
import css from './TopImage.module.scss';

interface Props {
  src: string;
  alt?: string;
  className?: string;
}

export default function TopImage({ src, alt = '', className = '' }: Props) {
  return (
    <div className={cn(css.imageWrapper, className)}>
      <img src={src} alt={alt} />
    </div>
  );
}
