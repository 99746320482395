import React from 'react';
import cn from 'classnames';
import css from './TextBlock.module.scss';

interface Props {
  title: string;
  texts: [{ title?: string; text: string }];
  className?: string;
}
export default function TextBlock({ title, texts, className = '' }: Props) {
  return (
    <div className={cn('flex flex-col items-center')}>
      <div className={cn(css.textBlock, className)}>
        <h3 className="font-bold">{title}</h3>
        <div className={css.texts}>
          {texts.map(({ title, text }, index) => {
            return (
              <div className={css.textItem} key={index}>
                {title && <h4 className="font-bold">{title}</h4>}
                <p>{text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
