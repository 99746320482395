import React from 'react';
// import useResponsiveQuery from "utils/useResponsiveQuery";
import tr from 'utils/translator';
import PageLayout from 'components/common/PageLayout';
import InfoBox from 'components/common/InfoBox';
import imageDesktop from 'assets/images/analytics/analytics-image.png';
import TopSection from 'components/common/TopSection';
import TopImage from 'components/common/TopImage';
import InfoSection from 'components/common/InfoSection';
import TextLeftRight from 'components/common/TextLeftRight';
import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';

import styles from './analytics.module.scss';

function Analytics() {
  // const isMobile = useResponsiveQuery();
  // const bg = isMobile ? imageMobile : imageDesktop;
  const infoTexts = tr.text('analytics.infoSection');

  return (
    <PageLayout isFullWidth>
      <div className={styles.analyticsContent}>
        <TopSection
          title={tr.text('analytics.topSection.title')}
          subtitle={tr.text('analytics.topSection.subTitle')}
          ctaLabel="Get started"
          ctaUrl={SUBSCRIPTION_PLANS_PAGE}
          isBlackTexts
        />
        <TopImage src={imageDesktop} alt="Storycards analytics" />
        <TextLeftRight title={tr.text('analytics.subTitle')} text={tr.text('analytics.subTitleDescription')} />
        <InfoSection items={infoTexts} />
      </div>
    </PageLayout>
  );
}

export default Analytics;
