import React from 'react';
import images from 'assets/images';
import icons from 'assets/icons';

import translator from 'utils/translator';
import PageLayout from 'components/common/PageLayout';
import TopSection from 'components/common/TopSection';
import TopImage from 'components/common/TopImage';
import TextBlock from './TextBlock';
import { SUBSCRIPTION_PLANS_PAGE } from 'components/constants';

import styles from './Security.module.scss';
import IconsBlock from './IconsBlock';

function Security() {
  const _t = (path: string) => translator.text(`security.${path}`);
  const iconsBlockData = [
    {
      icon: icons.securityIcon1,
      title: _t('iconsBlock.0.title'),
      text: _t('iconsBlock.0.text'),
    },
    {
      icon: icons.securityIcon2,
      title: _t('iconsBlock.1.title'),
      text: _t('iconsBlock.1.text'),
    },
    {
      icon: icons.securityIcon3,
      title: _t('iconsBlock.2.title'),
      text: _t('iconsBlock.2.text'),
    },
    {
      icon: icons.securityIcon4,
      title: _t('iconsBlock.3.title'),
      text: _t('iconsBlock.3.text'),
    },
  ];

  return (
    <PageLayout isFullWidth isWhiteHeader={false} className={styles.securityPageWrapper}>
      <div className={`${styles.securityPage} flex flex-col items-center `}>
        <TopSection
          title={_t('topSection.title')}
          subtitle={_t('topSection.subTitle')}
          ctaLabel="Get started"
          ctaUrl={SUBSCRIPTION_PLANS_PAGE}
        />
        <TopImage src={images.securityHero} className={styles.topImage} />
        <TextBlock title={_t('texts.0.title')} texts={_t('texts.0.paragrahs')} />
        <TextBlock title={_t('texts.1.title')} texts={_t('texts.1.paragrahs')} />
        <IconsBlock title={_t('iconsBlockTitle')} items={iconsBlockData} />
        <TextBlock title={_t('texts.2.title')} texts={_t('texts.2.paragrahs')} />
        <TextBlock title={_t('texts.3.title')} texts={_t('texts.3.paragrahs')} />
      </div>
    </PageLayout>
  );
}

export default Security;
